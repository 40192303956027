import { store } from '../vuex/store.js'

function loadUserData(){
	// Returning promise
	return axios.get('/api/user-side/getUserData', {
		validateStatus: null // Ignore interceptor in this case, as it will not trigger promise chained promise below
	}).then(response => {
		// If user is not initialized, act as there are no user
		if(response.data.user_initialized){
			storeUserData(store, response.data)
		}else{
			store.commit('setLoggedIn', false)
			store.commit('setUserInitialized', true)
		}
	}).catch(() => {
		store.commit('setUserInitialized', true)
	}).finally(() => {
		store.commit('setUserDataLoaded', true)
	})
}

function storeUserData(store, data){
	store.commit('setLoggedIn', true)
	store.commit('setUserFirstName', data.name)
	store.commit('setUserLastName', data.surname)
	store.commit('setUserPhone', data.primary_phone ? data.primary_phone.replace(/^\+/g, "") : "")
	store.commit('setReferralId', data.referral_id)
	store.commit('setUserPicture', data.picture_url)
	store.commit('setIsInMonthlyTop', !!data.is_in_monthly_top)
	store.commit('setMonthlyTopUrl', data.monthly_top_url)
	store.commit('setUserEmail', data.primary_email)
	store.commit('setUserHash', data.user_external_id_hash)
	store.commit('setConnectionStatuses', data.external_accounts || {})
	store.commit('setUserBirthday', data.birthday)
	store.commit('setApplyReferralCodeEnabled', data.applyReferralCodeEnabled)
	store.commit('setApplyPromoCodeEnabled', data.applyPromoCodeEnabled)
	store.commit('setNotificationSettings', {
		monthly: data.monthly_notification,
		challengeCompletion: data.challenge_completion_notification,
		newChallenges: data.new_challenges_notification,
		news: data.user_news_notification,
		newFriendConnected: data.new_friend_connected_notification,
	})
	store.commit('setUserInitialized', true)
}

function updateUserTotalPoints(value){
	return axios.get('/api/user-side/getUserTotalPointsEarned')
		.then(response => {
			store.commit('setUserTotalPoints', value ? value : (+response.data || 0))
		}).catch(error => {
			console.log('error', error)
		})
}

function updateUserPoints(value){
	return axios.get('/api/user-side/getUserPoints')
		.then(response => {
			var oldPoints = +store.state.userPoints || 0
			store.commit('setUserPoints', value ? value : (+response.data || 0))

			// Update total points as well if the value is increasing or when no total points are fetched yet
			if(store.state.userTotalPoints === false || oldPoints < store.state.userPoints){
				updateUserTotalPoints()
			}
		}).catch(error => {
			console.log('error', error)
		})
}

function loadRewards(){
		return axios.get('/api/user-side/getUserActiveRewards')
		.then(response => {
			store.commit('setRewards', response.data)

			// Tell all components that rewards are ready
			Events.$emit('on-rewards-updated')
		}).catch(error => {
			console.log('error', error)
		})
}

export {
	loadUserData,
	updateUserTotalPoints,
	updateUserPoints,
	loadRewards
}